// Functionality: This is the VehiclePerformanceDataTab component of VehiclePerformancePage.
import { useContext } from "react";
import VehiclePerformanceContext from "../../context/VehiclePerformanceProvider";
import VPDataSidebar from "./Data/VPDataSidebar";
import VPDataTable from "./Data/VPDataTable";
import { addEditSidebarVPColumnsOptions } from "../../constants/VehicleConfig";

export default function VehiclePerformanceDataTab({
  setActiveTab,
  activeTab,
  pageName,
}) {
  // Context: Vehicle Performance
  const {
    filtersIsLoading,
    vehiclePerformanceFilterVehicle,
    setVehiclePerformanceFilterVehicle,
    clearButtonFilter,
    setClearButtonFilter,
    setFilterString,
    vpDataSelectedColumns,
    setVpDataSelectedColumns,
    vehiclePerformanceFilterSidebar,
    setVehiclePerformanceFilterSidebar,
    generateSidebarFilterString,
  } = useContext(VehiclePerformanceContext);

  const mockData = localStorage.getItem("greenToken") === "mockData";

  return (
    <>
      <div className="vp-data-container-page">
        <VPDataSidebar
          page={"data"}
          filtersIsLoading={filtersIsLoading?.data}
          filterVehicle={vehiclePerformanceFilterVehicle}
          setFilterVehicle={setVehiclePerformanceFilterVehicle}
          clearButtonFilter={clearButtonFilter}
          setClearButtonFilter={setClearButtonFilter}
          setFilterString={setFilterString}
          setVehicleProfileDataPage={() => {}}
          vpDataSelectedColumns={vpDataSelectedColumns}
          setVpDataSelectedColumns={setVpDataSelectedColumns}
          addEditSidebarVPColumnsOptions={addEditSidebarVPColumnsOptions}
          vehiclePerformanceFilterSidebar={vehiclePerformanceFilterSidebar}
          setVehiclePerformanceFilterSidebar={
            setVehiclePerformanceFilterSidebar
          }
          generateSidebarFilterString={generateSidebarFilterString}
        />
        <VPDataTable
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          pageName={pageName}
          mockData={mockData}
          selectedColumns={vpDataSelectedColumns?.actual}
        />
      </div>
    </>
  );
}
