// Functionality: Gauge chart component for the application
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { colorGradientICR } from "../constants/ICRConfig";

export default function GaugeChart({ ratingValue, fontSize = "3rem Arial" }) {
  // Refs
  const gaugeChartCanvasRef = useRef(null); // Refs to Gauge Chart
  const chartType = "doughnut"; // Type of chart is doughnut

  // UE: Generate the Gauge Chart w/ Needle Pointer & Hover Annotation - Rerender when the ratingValue changes and on initial load
  useEffect(() => {
    if (!gaugeChartCanvasRef.current) return; // Ensure canvas is defined
    const updateRatingValue = ratingValue || 2;
    if (updateRatingValue) {
      const data = [updateRatingValue, 100 - updateRatingValue];
      const calculateColor = (ratingValue) =>
        updateRatingValue >= 0 && ratingValue <= 100
          ? colorGradientICR[
              Math.floor(updateRatingValue / (100 / colorGradientICR.length))
            ] || colorGradientICR?.[4]
          : colorGradientICR?.[0];

      // Const: Configuration for the Gauge Chart
      const gaugeChartConfig = {
        type: chartType,
        plugins: [
          {
            // Label Annotation Plugin
            afterDraw: (chart) => {
              const centerLabel = `${ratingValue || 0}`;

              const ctx = chart.ctx;
              const cw = chart.canvas.offsetWidth;
              const ch = chart.canvas.offsetHeight;
              const cx = cw / 2;
              const cy = ch / 1.5;

              ctx.save();
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillStyle = "black";
              ctx.font = fontSize;
              ctx.fillText(centerLabel, cx, cy);
              ctx.restore();
            },
          },
        ],
        data: {
          labels: [],
          datasets: [
            {
              data,
              updateRatingValue,
              backgroundColor: [calculateColor(updateRatingValue), "#F2F3F7"],
              borderWidth: 0,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          // aspectRatio: 1,
          layout: {
            padding: {
              bottom: 5,
            },
          },
          rotation: -90,
          cutout: "65%",
          circumference: 180,
          legend: {
            display: false,
          },
          animation: {
            animateRotate: false,
            animateScale: true,
          },
          plugins: {
            tooltip: {
              enabled: false,
            },
          },
        },
      };

      // Generate the Gauge Chart Component
      const gaugeChart = new Chart(
        gaugeChartCanvasRef.current,
        gaugeChartConfig
      );

      // Cleanup function when component is unmounted
      return () => {
        gaugeChart.destroy();
      };
    }
  }, [ratingValue, fontSize]);

  return (
    <canvas
      ref={gaugeChartCanvasRef}
      id="gauge-chart-container"
      className="gauge-chart"
    />
  );
}

// Props
const gaugeChartPropTypes = {
  ratingValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Specific rating value between range of 0 - 100
  fontSize: PropTypes.string, // Font size for the gauge chart
};

GaugeChart.propTypes = gaugeChartPropTypes;
