// This file serves as a central location for various paths used when making API calls throughout the application

// Authentication
export const LOGIN_URL = `/login`;
export const REGISTRATION_URL = `/registration`;
export const PING_URL = `/ping`;
export const DATES_URL = `/hinge`;
export const UPDATE_PASSWORD_URL = `/update_password`;
export const CREATE_PASSWORD_URL = `/create_password`;
export const FORGOT_PASSWORD_EMAIL_URL = `/forgot_password`;
export const FORGOT_PASSWORD_UPDATE_URL = `/verify_password`;

// Settings
export const VEHICLE_SETTINGS_URL = `/vehicle-settings`;
export const FUEL_TYPE_OPTIONS_URL = `/fueltypes`;
export const VIN_DECODER_URL = `/decode`;
export const ALL_VEHICLES_URL = `/allofthem`;

// Overview
export const COST_OF_CARBON_URL = `/costofcarbon`;
export const EMISSION_FOOTPRINT_BREAKDOWN_URL = ``;
export const TOTAL_EMISSION_FOOTPRINT_URL = `/totalemissionsfootprint`;
export const INDUSTRY_CARBON_RATING_URL = `/industryrating`;
export const VEHICLE_EMISSIONS_URL = `/highestemitting`;
export const FUEL_CONSUMPTION_URL = `/totalfuelconsumption`;

// Emissions
export const SCOPE_1_EMISSIONS_URL = `/scope1`;
export const SCOPE_2_EMISSIONS_URL = `/scope2managercard`;
export const SCOPE_1_DATA_POINTS_URL = `/scope1emissions`;
export const SCOPE_2_DATA_POINTS_URL = `/scopetwoemissionschart`;
export const SCOPE_2_MANAGER_URL = `/scope2manager`;
export const SCOPE_2_MANAGER_LOCATION_URL = `/locations`;
export const LIFECYCLE_EMISSIONS_URL = `/lifecycle`;

// Vehicle Performance
export const EMISSIONS_PER_VEHICLE_URL = `/emissionspervehicle`;
export const AVERAGE_EMISSIONS_PER_VEHICLE_URL = `/avgemissionsperveh`;
export const TOTAL_IDLE_TIME_URL = `/idletimescorecard`;
export const IDLE_TIME_EMISSIONS_URL = `/idletimeemissions`;
export const VEHICLE_PERFORMANCE_DATA_URL = `/vehperformance`;
export const VEHICLE_PROFILES_URL = `/vehicle-profiles`;
export const RANKED_VEHICLE_PROFILE_GROUP_URL = `/ranked-profiles`;

// Reduction
export const EMISSION_REDUCTION_TARGET_URL = `/emissionsreduction`;
export const EMISSION_REDUCTION_BASE_VALUES_URL = `/basevaluesreduction`;
export const EMISSIONS_FORECASTING_URL = `/forecast`;

// Reports
export const REPORTS_URL = `/reporting`;
