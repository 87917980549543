// Functionality: This is the VehicleProfileGroupPage component of VehiclePerformancePage.
import { useContext } from "react";
import VehiclePerformanceContext from "../../context/VehiclePerformanceProvider";
import VPProfileGroupCards from "./ProfileGroup/VPProfileGroupCards";
import useQueryParams from "../../hooks/use-queryParams";
import VehicleProfile from "./VehicleProfile";
import Skeleton from "react-loading-skeleton";
import VPDataSidebar from "./Data/VPDataSidebar";
import { addEditSidebarVPColumnsOptions } from "../../constants/VehicleConfig";

export default function VehicleProfileGroupPage({ setActiveTab, activeTab }) {
  // Get the query parameters from the URL for the username
  const query = useQueryParams();
  const selectedVehicle = query.get("vehicle-profile") || null;
  const mockData = localStorage.getItem("greenToken") === "mockData";

  // Context: Get Values from Vehicle Performance Context
  const {
    vehicleProfileData,
    filtersIsLoading,
    vehicleProfileFilterVehicle,
    setVehicleProfileFilterVehicle,
    clearButtonFilter,
    setClearButtonFilter,
    setProfileFilterString,
    vehicleProfileDataPage,
    setVehicleProfileDataPage,
    vehicleProfileFilterSidebar,
    setVehicleProfileFilterSidebar,
    generateSidebarFilterString,
  } = useContext(VehiclePerformanceContext);

  return (
    <>
      {selectedVehicle ? (
        <>
          <VehicleProfile
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            mockData={mockData}
          />
        </>
      ) : (
        <div className="vp-data-container-page">
          <VPDataSidebar
            page={"profile"}
            filtersIsLoading={filtersIsLoading?.data}
            filterVehicle={vehicleProfileFilterVehicle}
            setFilterVehicle={setVehicleProfileFilterVehicle}
            clearButtonFilter={clearButtonFilter}
            setClearButtonFilter={setClearButtonFilter}
            setFilterString={setProfileFilterString}
            setVehicleProfileDataPage={setVehicleProfileDataPage}
            vpDataSelectedColumns={[]}
            setVpDataSelectedColumns={() => {}}
            addEditSidebarVPColumnsOptions={addEditSidebarVPColumnsOptions}
            vehiclePerformanceFilterSidebar={vehicleProfileFilterSidebar}
            setVehiclePerformanceFilterSidebar={setVehicleProfileFilterSidebar}
            generateSidebarFilterString={generateSidebarFilterString}
          />
          {!vehicleProfileData?.loaded ? (
            <Skeleton height={"100%"} />
          ) : (
            <VPProfileGroupCards
              vehicleProfileData={vehicleProfileData}
              vehicleProfileDataPage={vehicleProfileDataPage}
              setVehicleProfileDataPage={setVehicleProfileDataPage}
            />
          )}
        </div>
      )}
    </>
  );
}
