import ApplicationHeader from "../layouts/ApplicationHeader";
import EmissionReductionTargetCard from "./ReductionPage/EmissionReductionTarget";
import EmissionsForecasting from "./ReductionPage/EmissionsForecasting";

export default function ReductionPage() {
  return (
    <>
      <ApplicationHeader headerName={"Reduction"} />
      <>
        <div id="reduction-page-container">
          <EmissionReductionTargetCard />
          <EmissionsForecasting />
        </div>
      </>
    </>
  );
}
