// This file serves as a central location for all configuration variables related to Reduction Page

// Emission Reduction Target Component
// Const: Reduction Target % Dropdown Data
const reductionTargetPercentageDropdownData = [
  5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
  100,
];
export const reductionTargetPercentageDropdown =
  reductionTargetPercentageDropdownData.map((value) => {
    return {
      label: `${value}%`,
      value: value,
    };
  });

// Const: Emission Reduction Target Data Config
export const emissionReductionTargetDataConfig = {
  labels: [],
  datasets: [
    {
      label: "Actual",
      backgroundColor: "rgba(0, 117, 210, 1)",
      borderWidth: 1,
      data: [],
      barPercentage: 0.2,
    },
    {
      label: "Target",
      backgroundColor: "rgba(0, 117, 210, 0.2)",
      borderWidth: 1,
      data: [],
      barPercentage: 0.6,
    },
  ],
};

// Const: Emission Forecasting Data Config
export const emissionForecastingDataConfig = {
  labels: [],
  actualData: [],
  forecastData: [],
  loaded: false,
};
