// Functionality: The Individual Card component for the Vehicle Performance Profile Group Page.
import { useContext } from "react";
import DateContext from "../../../context/DateProvider";
import { PiTruckLight } from "react-icons/pi";
import GaugeChart from "../../../visualizations/GaugeChart";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { vehiclePerformanceProfileDataLabels } from "../../../constants/VehicleConfig";
import SwitchCarousel from "../../../components/SwitchCarousel";
import Pagination from "../../../components/Pagination";

export default function VPProfileGroupCards({
  vehicleProfileData,
  vehicleProfileDataPage,
  setVehicleProfileDataPage,
}) {
  // Context: Get Values from Date Context
  const { dateValues, applicationDates, setApplicationDates } =
    useContext(DateContext);

  const location = useLocation();
  const navigate = useNavigate();

  // Set Selected Vehicle
  const setSelectedVehicle = (id) => {
    if (applicationDates?.vpProfile !== applicationDates?.vehicleProfile) {
      setApplicationDates({
        ...applicationDates,
        vehicleProfile: applicationDates?.vpProfile,
      });
    }
    // Update the query parameter in the URL
    const searchParams = new URLSearchParams(location.search);

    // Set the profile-id query parameter
    searchParams.set("vehicle-profile", id);

    // Construct the new URL
    const newUrl = `${location.pathname}?${searchParams.toString()}`;

    // Replace the current URL with the updated query parameter
    navigate(newUrl);
  };

  const firstGroup = ["vehicle_id", "make", "year", "fuel_type", "g_mile_co2"];

  const flattenedPerformanceLabels = Object.assign(
    {},
    ...Object.values(vehiclePerformanceProfileDataLabels)
  );

  const formatPreviewData = (dictionary, columns, item, addLabelCSS = "") => {
    return columns?.map((val, index) => {
      return (
        <div key={index + item} className="vp-profile-group-card-body-row">
          <p className={`vp-profile-group-card-body-row-label${addLabelCSS}`}>
            {dictionary?.[val]?.label}:
          </p>
          <p className="vp-profile-group-card-body-row-value">
            {item?.[val] || "-"}
          </p>
        </div>
      );
    });
  };
  const profileGroupCard = (data) => {
    return data?.map((item, index) => {
      return (
        <div className="vp-profile-group-card" key={index}>
          <div className="vp-profile-group-card-1">
            <div className="vp-profile-group-card-label">
              <p>
                {flattenedPerformanceLabels?.[firstGroup?.slice(0, 1)]?.label}
              </p>
              <p>{item?.[firstGroup?.slice(0, 1)]}</p>
            </div>
            <PiTruckLight />
            <p>Rank {item?.ranking}</p>
          </div>
          <div className="vp-profile-group-card-2">
            {formatPreviewData(
              flattenedPerformanceLabels,
              firstGroup?.slice(1, 3),
              item
            )}
          </div>
          <div className="vp-profile-group-card-2">
            {formatPreviewData(
              flattenedPerformanceLabels,
              firstGroup?.slice(3),
              item,
              "-2"
            )}
          </div>

          <div className="vp-profile-group-card-3">
            <p>EPA Rating:</p>
            <GaugeChart ratingValue={item?.rating} fontSize="1.125rem Arial" />
          </div>
          <div className="vp-profile-group-card-4">
            <FaArrowRightLong
              className="accordion-icon"
              onClick={() => setSelectedVehicle(item?.vehicle_id)}
            />
          </div>
        </div>
      );
    });
  };
  return (
    <div className="vehicle-group-container">
      <div className="vp-profile-title-group">
        <p>Vehicle Profile Data</p>
        <div className="vp-profile-icon-group">
          <div className="vp-profile-group-switch-carousel">
            <SwitchCarousel
              values={dateValues}
              applicationDates={applicationDates}
              setApplicationDates={setApplicationDates}
              applicationDatesLabel={"vpProfile"}
              fillArrowColor={"#66C743"}
              switchCarouselValueClassName="group-switch-carousel-value"
              changeVehicleProfile={true}
            />
          </div>
        </div>
      </div>
      {vehicleProfileData?.data?.length > 0 && (
        <Pagination
          pageCount={false}
          currentPage={vehicleProfileDataPage}
          onPageChange={setVehicleProfileDataPage}
          nextPage={vehicleProfileData?.next_page}
        />
      )}
      {vehicleProfileData?.data?.length > 0 ? (
        profileGroupCard(vehicleProfileData?.data)
      ) : (
        <div>No data available for selected filters or month. </div>
      )}
    </div>
  );
}
