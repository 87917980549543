import ApplicationHeader from "../layouts/ApplicationHeader";
import NewReportComponent from "./ReportsPage/NewReportComponent";
import { mockLoginData } from "../constants/mockDataConfig";

export default function ReportPages() {
  // Const: Determine if Mock User is Logged in
  const notMockUser =
    localStorage.getItem("greenToken") !== mockLoginData?.token;

  // Function to generate the question / ui/ux card for each group
  const generateQuestionGroup = (groupConfig, questionGroupConfig) => {
    return groupConfig?.map((question, index) => {
      return (
        <div className="report-question-group-container" key={question}>
          <p className="report-question-group-container-question">
            {groupConfig?.[index]}
          </p>
          {questionGroupConfig?.[index]}
        </div>
      );
    });
  };

  return (
    <>
      <ApplicationHeader headerName={"Reports"} />
      <NewReportComponent
        generateQuestionGroup={generateQuestionGroup}
        notMockUser={notMockUser}
      />
    </>
  );
}
