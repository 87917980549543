// Functionality: This file exports the Scope2NewAddress component which is used in the Scope2Manager component. The component is a card including the form to submit a new site location for Scope 2 emissions
import { useState } from "react";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import SearchBar from "../../../../components/SearchBar";
import Table from "../../../../components/Table";
import { scope2AddressManagerTableColumns } from "../../../../constants/EmissionsConfig";
import { CustomRadioItemGroup } from "../../../../components/RadioItemGroup";
import Scope2DeleteAddress from "./Scope2DeleteAddress";
import Scope2EditAddress from "./Scope2EditAddress";
import { deleteSiteLocation } from "../../../../api/Emissions/Scope2Manager/deleteSiteLocationAPI";
import { putSiteLocation } from "../../../../api/Emissions/Scope2Manager/putSiteLocationAPI";
import { isScope2ManagerAddressManager } from "../../../../constants/initialDataConfig";

export default function Scope2NewAddress({
  scope2ManagerNewAddress,
  handleNewAddressEntry,
  resetScope2ManagerFields,
  submitNewScope2Address,
  submitConditionHandlerAddress,
  nonEmptyStateCondition,
  stateOptionsConfig,
  selectedStateScope,
  setSelectedStateScope,
  scope2ManagerAddressOptions,
  selectedLocationEditDelete,
  setSelectedLocationEditDelete,
  setSuccessMsg,
  handleModalClose,
}) {
  // State: Selected Tab
  const [selectedTab, setSelectedTab] = useState("new_site_location");
  const [editAddressFields, setEditAddressFields] = useState({
    siteName: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    site_id: "",
  });
  // State: Selected State Scope
  const [selectedStateScopeEdit, setSelectedStateScopeEdit] = useState([]);

  // Const Tabbed View Options
  const siteLocationManagerTabs = [
    { label: "New Site Location Entry", value: "new_site_location" },
    {
      label: "Manage Existing Site Locations",
      value: "existing_site_locations",
    },
  ];

  // Const: Determing if Editing, Deleting, or Adding New Address
  const editDeleteAddAddress =
    typeof selectedLocationEditDelete?.type === "string";

  // Const: Radio Item Group
  const radioItemGroup = (
    <CustomRadioItemGroup
      options={siteLocationManagerTabs}
      selectedOption={selectedTab}
      setSelectedOption={setSelectedTab}
      divClassName="custom-radio-item-container-linear"
      elementClassName="custom-radio-item-option-linear"
    />
  );

  // Function: Handle Edit Address
  const handleEditDeleteState = (data, type) => {
    const selectedLocation = data?.row?.original?.value;
    const selectedLocationLabel = data?.row?.original?.label;
    const locationId = selectedLocation?.site_id;

    if (type === "edit") {
      setSelectedLocationEditDelete({
        selectedLocationLabel: selectedLocationLabel,
        selectedLocationId: locationId,
        type: "edit",
      });
      const selectedState = stateOptionsConfig?.filter((item) => {
        return item?.value === selectedLocation?.state;
      });
      setSelectedStateScopeEdit(selectedState);

      setEditAddressFields({
        siteName: selectedLocation?.site_name,
        streetAddress: selectedLocation?.street_address,
        city: selectedLocation?.city,
        state: selectedLocation?.state,
        zipCode: selectedLocation?.zip_code,
        site_id: locationId,
      });
    } else if (type === "delete") {
      setSelectedLocationEditDelete({
        selectedLocationLabel: selectedLocationLabel,
        selectedLocationId: locationId,
        type: "delete",
      });
    }
  };

  // Function: Handle Edit Address
  const handleEditDeleteAddress = (type) => {
    if (type === "edit") {
      putSiteLocation(
        editAddressFields,
        setEditAddressFields,
        setSelectedStateScopeEdit,
        setSuccessMsg,
        setSelectedLocationEditDelete
      );
    } else if (type === "delete") {
      deleteSiteLocation(
        selectedLocationEditDelete?.selectedLocationId,
        setSuccessMsg,
        setSelectedLocationEditDelete
      );
    }
    handleModalClose("site_location_manager");
    setTimeout(() => setSuccessMsg({ text: "", msg: "" }), 5000);
  };

  // Const: Address Options View
  const addressOptionsContainer = scope2ManagerAddressOptions?.loaded && (
    <div className="scope2-address-data-table">
      <Table
        config={scope2AddressManagerTableColumns(handleEditDeleteState)}
        tableData={scope2ManagerAddressOptions?.data}
        tableRowClassName="primary-table-row card-text"
        sortable={false}
        tableHeadClassName="primary-table-sort-header-group card-text-min"
      />
    </div>
  );
  // Const: Input New Scope 2 Address Container Group
  const addressFields = (
    <div className="scope2-manage-address-group font-color-gray font-small-medium">
      <div>
        <>
          {scope2ManagerAddressOptions?.data?.length === 0 && (
            <p className="card-title">New Site Location Entry</p>
          )}
        </>
        <div className="scope2-entry-form-group">
          <div className="scope2-manage-input-group-address">
            <p>Site Name:</p>
            <Input
              className="input-element scope2-manage-input-element"
              onChange={(e) => handleNewAddressEntry(e, "siteName")}
              value={scope2ManagerNewAddress?.siteName}
            />
          </div>
          <div className="scope2-manage-input-group-address">
            <p>Street Address:</p>
            <Input
              className="input-element scope2-manage-input-element"
              onChange={(e) => handleNewAddressEntry(e, "streetAddress")}
              value={scope2ManagerNewAddress?.streetAddress}
            />
          </div>
          <div className="flex-row-space-between">
            <div className="scope2-manage-input-group-address">
              <p>City:</p>
              <Input
                className="input-element scope2-manage-input-element"
                onChange={(e) => handleNewAddressEntry(e, "city")}
                value={scope2ManagerNewAddress?.city}
              />
            </div>
            <div className="scope2-manage-input-group-address">
              <p>State:</p>
              <SearchBar
                options={stateOptionsConfig}
                selectedOptions={selectedStateScope}
                setSelectedOptions={setSelectedStateScope}
                isMultiSelect={false}
                placeholder="Select State"
              />
            </div>
            <div className="scope2-manage-input-group-address">
              <p>Zip Code:</p>
              <Input
                className="input-element scope2-manage-input-element"
                onChange={(e) => handleNewAddressEntry(e, "zipCode")}
                value={scope2ManagerNewAddress?.zipCode}
              />
            </div>
          </div>
        </div>
        <div className="scope2-submit-entry-button flex-end-button-group">
          <Button
            buttonName="Clear Entry"
            className="secondary-blue-button"
            onClick={() => resetScope2ManagerFields("new_address")}
          />
          <Button
            buttonName="Submit Location"
            onClick={() => submitNewScope2Address()}
            disabled={!submitConditionHandlerAddress}
            className="primary-blue-button"
          />
        </div>
      </div>
    </div>
  );

  const warningMessageDelete = selectedLocationEditDelete?.type ===
    "delete" && (
    <Scope2DeleteAddress
      selectedLocationEditDelete={selectedLocationEditDelete}
      setSelectedLocationEditDelete={setSelectedLocationEditDelete}
      handleEditDeleteAddress={handleEditDeleteAddress}
      isScope2ManagerAddressManager={isScope2ManagerAddressManager}
    />
  );

  const warningMessageEdit = selectedLocationEditDelete?.type === "edit" && (
    <Scope2EditAddress
      selectedLocationEditDelete={selectedLocationEditDelete}
      setSelectedLocationEditDelete={setSelectedLocationEditDelete}
      handleEditDeleteAddress={handleEditDeleteAddress}
      setEditAddressFields={setEditAddressFields}
      editAddressFields={editAddressFields}
      stateOptionsConfig={stateOptionsConfig}
      selectedStateScopeEdit={selectedStateScopeEdit}
      setSelectedStateScopeEdit={setSelectedStateScopeEdit}
      isScope2ManagerAddressManager={isScope2ManagerAddressManager}
    />
  );

  const generalView = (
    <>
      {!editDeleteAddAddress && (
        <>
          {scope2ManagerAddressOptions?.data?.length > 0 && radioItemGroup}
          {selectedTab === "existing_site_locations" && addressOptionsContainer}
          {selectedTab === "new_site_location" && addressFields}
        </>
      )}
    </>
  );
  return (
    <>
      {generalView}
      {warningMessageDelete}
      {warningMessageEdit}
    </>
  );
}
