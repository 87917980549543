// Functionality: This file exports the Scope2PreviousSubmissionsFilter component which is used in the Scope2PreviousSubmissions component. The component is a filter for the previous submissions for Scope 2 emissions
import Accordion from "../../../../components/Accordion";
import Button from "../../../../components/Button";
import RangeSlider from "../../../../components/RangeSlider";
import SearchBar from "../../../../components/SearchBar";
import filter from "../../../../assets/icons/filter.png";

export default function Scope2PreviousSubmissionsFilter({
  setScope2ManagerEntriesTableData,
  originalScope2ManagerEntries,
  setScope2TableFilters,
  scope2TableFilters,
  scope2LocationFilters,
  scope2ManagerElectricityPurchasedFilters,
  setScope2ManagerElectricityPurchasedFilters,
  scope2ManagerInvoiceIdFilters,
  isScope2TableFilters,
}) {
  // Function: Handle Submit Filters
  const handleSubmitFilters = () => {
    const minMaxFilterData = originalScope2ManagerEntries?.filter((row) => {
      return (
        parseFloat?.(row.electricity_purchased_kwh) >=
          parseFloat?.(scope2ManagerElectricityPurchasedFilters?.data[0]) &&
        parseFloat?.(row.electricity_purchased_kwh) <=
          parseFloat?.(scope2ManagerElectricityPurchasedFilters?.data[1])
      );
    });

    const locationFilterData =
      scope2TableFilters?.location?.length > 0
        ? minMaxFilterData?.filter((row) => {
            return scope2TableFilters?.location?.some(
              (location) => location?.value === row?.location
            );
          })
        : minMaxFilterData;

    const invoiceIdFilterData =
      scope2TableFilters?.invoice_id?.length > 0
        ? locationFilterData?.filter((row) => {
            return scope2TableFilters?.invoice_id?.some(
              (invoiceId) => invoiceId?.value === row?.invoice_id
            );
          })
        : locationFilterData;

    setScope2ManagerEntriesTableData({
      loaded: true,
      data: invoiceIdFilterData,
    });
  };

  // Function: Handle Clear Filters
  const handleClearFilters = () => {
    setScope2TableFilters(isScope2TableFilters);
    setScope2ManagerElectricityPurchasedFilters((prevValues) => {
      return { ...prevValues, data: prevValues?.minMax };
    });
    setScope2ManagerEntriesTableData((prevData) => {
      return { loaded: true, data: originalScope2ManagerEntries };
    });
  };

  // Function: Handle Dropdown Change
  const handleDropdownChange = (option, label) => {
    setScope2TableFilters((prevSelectedOptions) => {
      const isSelected = prevSelectedOptions?.[label]?.some(
        (item) => item.value === option.value
      );
      if (isSelected) {
        return {
          ...prevSelectedOptions,
          [label]: prevSelectedOptions?.[label]?.filter(
            (item) => item.value !== option.value
          ),
        };
      } else {
        return {
          ...prevSelectedOptions,
          [label]: [...prevSelectedOptions?.[label], option],
        };
      }
    });
  };

  // Const: Clear Button Filter
  const clearFiltersButton = (
    <div className="clear-button-filter-container">
      <Button buttonName={"Clear"} onClick={() => handleClearFilters()} />
    </div>
  );
  // Const: Submit Button Filter
  const submitFiltersButton = (
    <div className="submit-button-filter-container">
      <Button buttonName={"Submit"} onClick={() => handleSubmitFilters()} />
    </div>
  );

  // Const: Location Filter
  const scope2LocationFilter = (
    <Accordion
      type="scope2_location"
      titleName="Location"
      children={
        <>
          <div className="vp-sidebar-nested-group">
            <SearchBar
              options={scope2LocationFilters}
              isMultiSelect={true}
              selectedOptions={scope2TableFilters?.location}
              onChange={(e) => handleDropdownChange(e, "location")}
              placeholder="Search by Location"
            />
          </div>
        </>
      }
      titleClassName="vp-sidebar-accordion-group"
    />
  );

  // Const: Electricity Purchased Filter
  const scope2ElectricityPurchased = (
    <Accordion
      type="scope2_electricity_purchased"
      titleName="Electricity Purchased"
      children={
        <>
          <RangeSlider
            values={scope2ManagerElectricityPurchasedFilters}
            setValues={setScope2ManagerElectricityPurchasedFilters}
          />
        </>
      }
      titleClassName="vp-sidebar-accordion-group"
    />
  );

  //   // Const: Date Filter
  //   const scope2DateFilter = (
  //     <Accordion
  //       type="scope2_date"
  //       titleName="Date"
  //       children={<></>}
  //       titleClassName="vp-sidebar-accordion-group"
  //     />
  //   );

  // Const: Invoice ID Filter
  const scope2InvoiceID = (
    <Accordion
      type="scope2_invoice_id"
      titleName="Invoice ID"
      children={
        <>
          <div className="vp-sidebar-nested-group">
            <SearchBar
              options={scope2ManagerInvoiceIdFilters}
              isMultiSelect={true}
              selectedOptions={scope2TableFilters?.invoice_id}
              onChange={(e) => handleDropdownChange(e, "invoice_id")}
              placeholder="Search by Invoice"
            />
          </div>
        </>
      }
      titleClassName="vp-sidebar-accordion-group"
    />
  );

  // Const: Previous Submissions Filters
  const previousSubmissionFilters = (
    <div className="scope2-filter-container">
      <div className="vp-sidebar-header-group">
        <p>Filter Submissions</p>
        <img src={filter} alt={"filter"} />
      </div>
      <div className="scope2-filters-scroll-group">
        {scope2LocationFilter}
        {scope2ElectricityPurchased}
        {/* {scope2DateFilter} */}
        {scope2InvoiceID}
      </div>
      <div className="scope2-button-filter-container-group">
        {clearFiltersButton}
        {submitFiltersButton}
      </div>
    </div>
  );
  return <> {previousSubmissionFilters}</>;
}
