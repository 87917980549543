// This file serves as a central location for all configuration variables related to Vehicle Performance
import UniversalTableConfig from "./UniversalTableConfig.js";
import { PiPencilLight } from "react-icons/pi";
import {
  driveIdleRatioVehicleProfile,
  daysActiveVehicleProfile,
} from "./TileConfig.js";
// Highest Emitting Vehicles (HEV)
const highestEmittingBenchmark = 3.5;
// HEV Columns
export const highestEmittingVehiclesColumns = ["vehicle"];
// HEV Table Config
export const highestEmittingVehiclesTableConfig = (
  emissionLabel,
  tableNumber,
  handleRouteVehicleProfile
) => {
  // Add a new column for observations numbers
  const observationColumn = [
    {
      Header: " ",
      accessor: (originalRow, rowIndex) => {
        // Determine the color of the circle based on the row index
        let circleColor;
        let adjustedIndex = rowIndex;
        let numberValue = parseFloat(originalRow?.emissions);
        if (tableNumber === 2) {
          adjustedIndex += 4;
        }

        if (numberValue < highestEmittingBenchmark) {
          circleColor = "-green";
        } else {
          circleColor = "-red";
        }
        return (
          <div
            className={`table-observation-number-col table-observation-number-col${circleColor}`}
          >
            <span className="table-observation-number-col-text">
              {adjustedIndex + 1}
            </span>
          </div>
        );
      },
    },
  ];
  // Add the rest of the columns
  const columnsConfig = UniversalTableConfig(
    highestEmittingVehiclesColumns,
    handleRouteVehicleProfile
  );

  // Add the emissions output configuration
  const emissionsConfig = [
    {
      Header: "Emissions",
      accessor: (hevData) => hevData["emissions"],
      Cell: (row) => {
        const { value } = row.cell;
        return (
          <span className="table-emission-col-group">
            <p className="table-emission-col-group-value">{value}</p>
            <p className="table-emission-col-group-label">{emissionLabel}</p>
          </span>
        );
      },
    },
  ];
  return observationColumn.concat(columnsConfig).concat(emissionsConfig);
};

// Const: Emissions Per Vehicle Data Config
export const emissionsPerVehicleDataConfig = {
  label: "CO2",
  dataValues: [],
};
// Const: Emission Per Vehicle Sort Data Config
export const emissionsPerVehicleSortDataConfig = [
  { label: "Ascending", value: "asc" },
  { label: "Descending", value: "desc" },
  { label: "Default", value: "default" },
];
// Const: Emissions Per Vehicle Benchmark Config
export const emissionsPerVehicleBenchmarkConfig = {
  label: "Industry Benchmark",
  dataValues: [],
  trendline: true,
  pointRadius: 0,
  color: "rgba(255, 31, 46, 1)",
  type: "line",
  width: 1,
};

// Const:  Idle Time Emissions Data Config
export const idleTimeEmissionsDataConfig = {
  label: "CO2",
  dataValues: [],
  trendline: false,
  pointRadius: 2,
  color: "rgba(102, 199, 67, 1)",
};
// Const: Idle Time Emissions Benchmark Config
export const idleTimeEmissionsBenchmarkConfig = {
  label: "CO2 Trendline",
  dataValues: [],
  trendline: true,
  pointRadius: 0,
  color: "rgba(255, 31, 46, 1)",
};

// Vehicle Performance Data Table
// VP Data Table Columns
const addEditSidebarVPColumns = [
  "Vehicle ID",
  "Total CO2e Emissions",
  "CO2 Emissions",
  "NO2 Emissions",
  "CH4 Emissions",
  "Idle Time Emissions",
  "Idle Time",
  "EPA Rating",
  "Engine Model",
  "Engine Manufacturer",
  "Year",
  "MPG",
  "Emissions Efficiency (g CO2 / mile)",
  "Idle Fuel Used",
  "Primary Fuel Type",
];
const addEditSidebarVPColumnValues = [
  "vehicle_id",
  "total_emissions_mt",
  "emissions_by_fuel_co2_mt",
  "emissions_by_dist_n2o_mt",
  "emissions_by_dist_ch4_mt",
  "idle_time_emissions_by_fuel_co2_mt",
  "total_idle_time_hrs",
  "rating",
  "engine_model",
  "engine_manufacturer",
  "year",
  "miles_per_gallon",
  "g_mile_co2",
  "idle_fuel_used",
  "fuel_type",
];
export const addEditSidebarVPColumnsOptions = addEditSidebarVPColumns.map(
  (item) => ({
    label: item,
    value: addEditSidebarVPColumnValues[addEditSidebarVPColumns.indexOf(item)],
  })
);
export const vpDataTableColumns = [
  "vehicle_id",
  "total_emissions_mt",
  "emissions_by_fuel_co2_mt",
  "idle_time_emissions_by_fuel_co2_mt",
  "total_idle_time_hrs",
  "emissions_by_dist_ch4_mt",
  "emissions_by_dist_n2o_mt",
];
export const vpDataFilterOptionMapping = {
  loaded: false,
  filters: {
    total_emissions_mt: {
      type: "rangeslider",
      label: "Total CO2e Emissions",
      unit: "MT CO2e",
      data: [0, 0],
      minMax: [0, 0],
    },
    total_idle_time_hrs: {
      type: "rangeslider",
      label: "Idle Time",
      unit: "Hours",
      data: [0, 0],
      minMax: [0, 0],
    },
    idle_time_emissions_by_fuel_co2_mt: {
      type: "rangeslider",
      label: "Idle Time Emissions",
      unit: "MT",
      data: [0, 0],
      minMax: [0, 0],
    },
    emissions_by_fuel_co2_mt: {
      type: "rangeslider",
      label: "CO2 Emissions",
      unit: "MT",
      data: [0, 0],
      minMax: [0, 0],
    },
    emissions_by_dist_ch4_mt: {
      type: "rangeslider",
      label: "CH4 Emissions",
      unit: "MT",
      data: [0, 0],
      minMax: [0, 0],
      stepValue: 0.0001,
    },
    emissions_by_dist_n2o_mt: {
      type: "rangeslider",
      label: "N2O Emissions",
      unit: "MT",
      data: [0, 0],
      minMax: [0, 0],
      stepValue: 0.00001,
    },
  },
};
export const vpProfileOptionMapping = {
  ...vpDataFilterOptionMapping,
  filters: {
    ...vpDataFilterOptionMapping?.filters,
    rating: {
      type: "rangeslider",
      label: "EPA Rating",
      data: [0, 100],
      minMax: [0, 100],
      stepValue: 1,
    },
  },
};

// VP Data Table Config
export const vpDataTableConfig = (
  handleRouteVehicleProfile,
  columns = vpDataTableColumns
) => {
  // Add the rest of the columns
  const columnsConfig = UniversalTableConfig(
    columns,
    handleRouteVehicleProfile
  );
  return columnsConfig;
};

// Vehicle Performance Profile Group Data Labelling
export const vehiclePerformanceProfileDataLabels = {
  profile: {
    vehicle_id: { label: "Vehicle", unit: "" },
    vin: { label: "VIN", unit: "" },
    make: { label: "Make", unit: "" },
    year: { label: "Year", unit: "" },
    // vehicle_type: { label: "Type", unit: "" },
    engine_type: { label: "Engine", unit: "" },
  },
  trip_details: {
    total_estimated_mileage_miles: { label: "Distance", unit: "Miles" },
    miles_per_gallon: { label: "Miles / Gallon", unit: "MPG" },
    total_idle_time_hrs: { label: "Idle Time", unit: "Hours" },
    total_idle_time_fuel_consumed: { label: "Idle Fuel Used", unit: "Gallons" },
    gallons_per_idle_hour: {
      label: "Gallons / Idle Hour",
      unit: "Gallons / Hour",
    },
    total_drivetime: { label: "Drive Time", unit: "Hours" },
    drive_to_idle_ratio: {
      label: "Drive to Idle Ratio",
      unit: "%",
      blurb: driveIdleRatioVehicleProfile,
    },
    days_active: {
      label: "Days Active",
      unit: "Days",
      blurb: daysActiveVehicleProfile,
    },
  },
  fuel_details: {
    fuel_type: { label: "Fuel Type", unit: "" },
    total_fuel_consumed_gallons: { label: "Fuel Used", unit: "Gallons" },
    total_fuel_purchased_gallons: {
      label: "Gallons Purchased",
      unit: "Gallons",
    },
    total_amt_spent_fuel: {
      label: "Dollar Spent",
      unit: "$",
      unitBefore: true,
    },
  },
  analytics: {
    g_mile_co2: { label: "Grams / Mile CO2", unit: "g / Mile" },
    emissions_by_fuel_co2_mt: { label: "Carbon Emissions", unit: "MT" },
    emissions_by_dist_ch4_mt: { label: "CH4 Emissions", unit: "kg" },
    emissions_by_dist_n2o_mt: { label: "N2O Emissions", unit: "kg" },
    idle_time_emissions_by_fuel_co2_mt: { label: "Idle Emissions", unit: "MT" },
    emissions_per_idle_hour: {
      label: "Emissions / Idle Hour",
      unit: "kg / Hour",
    },
    total_emissions_mt: { label: "Total CO2e Emissions", unit: "MT CO2e" },

    ranking: { label: "Fleet Ranking", unit: "" },
  },
};

// Year Values For Vehicle Settings
const yearValues1965Today = Array.from(
  { length: 2024 - 1965 + 1 },
  (v, i) => i + 1965
).sort((a, b) => b - a);
export const yearOptions1965Today = {
  information: yearValues1965Today.map((year) => {
    return {
      value: JSON.stringify(year),
      label: JSON.stringify(year),
    };
  }),
};
