// Responsible for being inserted into the DOM root element - holds the whole application
import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ApplicationLayout from "./layouts/ApplicationLayout";
import EmissionsPage from "./pages/EmissionsPage";
import LoginPage from "./pages/LoginPage";
import OverviewPage from "./pages/OverviewPage";
import ReductionPage from "./pages/ReductionPage";
import ReportsPage from "./pages/ReportsPage";
import SettingsPage from "./pages/SettingsPage";
import VehiclePerformancePage from "./pages/VehiclePerformancePage";
import RegistrationOnboardingFormPage from "./pages/RegistrationOnboardingFormPage";
import RegistrationInvitedOnboardingFormPage from "./pages/RegistrationInvitedFormPage";
import SignupPage from "./pages/SignupPage";
import HelpPage from "./pages/HelpPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import DemoOnboardPage from "./pages/DemoOnboardPage";
import { VehiclePerformanceProvider } from "./context/VehiclePerformanceProvider";
import { ReductionProvider } from "./context/ReductionProvider";
import { OverviewProvider } from "./context/OverviewProvider";
import { AuthProvider } from "./context/AuthProvider";
import { EmissionsProvider } from "./context/EmissionsProvider";
import { DateProvider } from "./context/DateProvider";
import { ReportProvider } from "./context/ReportsProvider";
import { SignupProvider } from "./context/SignupProvider";
import { SettingsProvider } from "./context/SettingsProvider";
import { DemoOnboardProvider } from "./context/DemoOnboardProvider";

export default function App() {
  // Gather the token from local storage - if it exists
  const [token, setToken] = useState(localStorage.getItem("greenToken"));

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth > 900 ? true : false,
    height: window.innerHeight,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth > 900 ? true : false,
        height: window.innerHeight,
      });
    }

    function updateToken() {
      setToken(localStorage.getItem("greenToken"));
    }

    window.addEventListener("resize", handleResize);
    window.addEventListener("local-storage-clear", updateToken);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("local-storage-clear", updateToken);
    };
  }, []);
  // useEffect(() => {
  //   function updateToken() {
  //     setToken(localStorage.getItem("greenToken"));
  //   }
  //   window.addEventListener("local-storage-clear", updateToken);
  // }, [token]);

  return (
    <AuthProvider>
      <DateProvider>
        <Routes>
          <Route
            path="/registration-form"
            element={(() => {
              if (!token) {
                return <RegistrationOnboardingFormPage />;
              }
            })()}
          />
          <Route
            path="/registration-form-invited"
            element={(() => {
              if (!token) {
                return <RegistrationInvitedOnboardingFormPage />;
              }
            })()}
          />
          {!token && (
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          )}
          {!token && <Route path="/" element={<LoginPage />} />}
          <Route>
            {!token && (
              <Route
                path="signup"
                element={
                  <SignupProvider>
                    <SignupPage />
                  </SignupProvider>
                }
              />
            )}
          </Route>
          <Route>
            {!token && (
              <Route
                path="demo-onboard"
                element={
                  <DemoOnboardProvider>
                    <DemoOnboardPage />
                  </DemoOnboardProvider>
                }
              />
            )}
          </Route>
          <Route element={<ApplicationLayout windowSize={windowSize} />}>
            {token && (
              <Route
                path=""
                element={(() => {
                  if (token) {
                    return (
                      <OverviewProvider>
                        <OverviewPage windowSize={windowSize} />
                      </OverviewProvider>
                    );
                  }
                  return <Navigate to="/" />;
                })()}
              />
            )}
            <Route
              path="emissions"
              element={(() => {
                if (token) {
                  return (
                    <EmissionsProvider>
                      <EmissionsPage />
                    </EmissionsProvider>
                  );
                }
                return <Navigate to="/" />;
              })()}
            />
            <Route
              path="reduction"
              element={(() => {
                if (token) {
                  return (
                    <ReductionProvider>
                      <ReductionPage />
                    </ReductionProvider>
                  );
                }
                return <Navigate to="/" />;
              })()}
            />
            <Route
              path="reports"
              element={(() => {
                if (token) {
                  return (
                    <ReportProvider>
                      <ReportsPage />
                    </ReportProvider>
                  );
                }
                return <Navigate to="/" />;
              })()}
            />
            <Route
              path="vehicle-performance"
              element={(() => {
                if (token) {
                  return (
                    <VehiclePerformanceProvider>
                      <VehiclePerformancePage />
                    </VehiclePerformanceProvider>
                  );
                }
                return <Navigate to="/" />;
              })()}
            />
            <Route
              path="settings"
              element={(() => {
                if (token) {
                  return (
                    <SettingsProvider>
                      <SettingsPage />
                    </SettingsProvider>
                  );
                }
                return <Navigate to="/" />;
              })()}
            />
            <Route
              path="help"
              element={(() => {
                if (token) {
                  return <HelpPage />;
                }
                return <Navigate to="/" />;
              })()}
            />
          </Route>
          {/* Reroute to / if the path does not exist */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </DateProvider>
    </AuthProvider>
  );
}
