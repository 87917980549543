// This file serves as a central location for all Initial Data That Will Be Used in the Application - This includes any hardcoded values that are not coming from the API, but are also not mock.
import { scope2ManagerMetricsMeasure } from "./EmissionsConfig";

// Date Provider - Year Values
export const isYearValues = {
  information: [],
  projection: [],
};

// Date Provider - Component Values
export const isApplicationDates = {
  emissionFootprint: "",
  overviewRightTiles: "",
  vehicleEmissions: "",
  industryCarbonRating: "",
  scope1Emissions: "",
  scope1ChartEmissions: "",
  scope2Emissions: "",
  emissionReductionTarget: "",
  emissionsPerVehicle: "",
  avgEmissionsPerVehicle: "",
  totalIdleTime: "",
  idleChartEmissions: "",
  vpData: "",
  wellToTank: "",
  tankToWheel: "",
  lifecycle: "",
  lifecycleChart: "",
};

// Date Provider - Daily v Monthly (No historical data)
export const isDailyMonthlyRadioItemOptions = {
  accessHistorialData: false,
  scope1: "",
  idle_emissions: "",
  lifecycle: "",
};
// Emissions Provider
export const isScope2ManagerNewEntry = {
  billDate: "",
  siteLocation: [],
  wattage: "",
  unit: scope2ManagerMetricsMeasure?.slice(0, 1),
  invoiceId: "",
};
export const isScope2ManagerNewEntryEdit = {
  entry_id: "",
  invoice_id: "",
  bill_date: "",
  wattage: "",
  site_id: "",
  siteLocation: "",
};
export const isScope2ManagerNewAddress = {
  siteName: "",
  streetAddress: "",
  city: "",
  state: "",
  zipCode: "",
};
export const isScope2ManagerAddressManager = {
  selectedLocationLabel: null,
  selectedLocationId: null,
  type: null,
};
export const isScope2ManagerEntryEdit = {
  selectedEntryLabel: null,
  selectedEntryId: null,
  type: null,
};
export const isScope2TableFilters = {
  location: [],
  electricity_purchased: [],
  // date: [],
  invoice_id: [],
};
// Reduction Provider
export const isEmissionReductionProjectedYear = [
  { label: "2035", value: "2035" },
];
export const isConstantEmissionReductionProjectedYear = 2035;
export const isSelectedEmissionTargetPercentage = [
  { label: "40%", value: "40" },
];
export const isReductionTargetDefault = {
  loaded: false,
  base_year: [],
  target_percentage: [],
  projection_year: [],
};
// TODO: REFACTOR STATE TO FOLLOW THIS SCHEMA
export const isReductionTargetState = {
  loaded: false,
  base_year: {
    default: [],
    fe_selection: [],
    options: [],
  },
  target_percentage: {
    default: [],
    fe_selection: [],
    options: [],
  },
  projection_year: {
    default: [],
    fe_selection: [],
    options: [],
  },
  curr_year: {
    default: [],
    fe_selection: [],
    options: [],
  },
};
// Failed Vin Decoder Initial State
export const isFailedDecoderState = {
  data: [],
  upload_data: [],
  mapping: {},
  loaded: false,
  failed_length: 0,
  upload_length: 0,
};
